/* Demo Styles */

.traffic-status {
  .item {
    .color-border {
      width: 15px;
      height: 3px;
      margin-top: 5px;
    }
    &:nth-child(1) {
      .color-border {
        background: theme-color(info);
      }
    }
    &:nth-child(2) {
      .color-border {
        background: theme-color(primary);
      }
    }
    &:nth-child(3) {
      .color-border {
        background: theme-color(danger);
      }
    }
  }  
}

.regional-chart-legend {
  .item {
    .item-box {
      width: 15px;
      height: 8px;
    }
  }
}

.server-status-legend {
  .item {
    .color-bullet {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      margin-right: 5px;
    }
    &:nth-child(1) {
      .color-bullet {
        background: #d8d8d8;
      }
    }
    &:nth-child(2) {
      .color-bullet {
        background: theme-color(primary);
      }
    }
    &:nth-child(3) {
      .color-bullet {
        background: theme-color(info);
      }
    }
  }
}
#proBanner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  background: #000;
  .card-body-padding {
    padding-top: .55rem ;
    padding-bottom: .55rem;
    padding-left: 2rem;
    padding-right: 2rem;
    @media (max-width: 766px) {
    padding-left: 1rem;
    padding-right: 1rem;
    }
  }
  .buy-now-text {
    color: $white;
    @media (max-width: 766px) {
      font-size: 10px;
      line-height: 1.5;
    }
  }
  .btn {
    &.buy-now-btn {
      background: transparent;
      color: #88b6ff;
      border-radius: 10px;
      // padding: 0.625rem 1.25rem;
      padding: 1rem 0;
      border-radius: 4px;
      font-weight: bold;
      font-size: 1rem;
      @media (max-width: 766px) {
        min-width: 80px;
        padding: 1rem .5rem;
        font-size: 12px;
      }
    }
    &#bannerClose { 
      i {
        margin-right: 1rem;
        font-size: 1.25rem;
        @media (max-width: 766px) {
          font-size: 1rem;
          margin-right: 0;
        }
      }
    }
  }
  a {
    text-decoration: none;
    i {
      font-size: 1.25rem;
      @media (max-width: 766px) {
        font-size: 1rem;
      }
    }
  }
  
}
.proBanner-padding-top {
  padding-top: $buy-nowbanner-padding-top !important;
}
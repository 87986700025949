/* Navbar */

.navbar {
  font-weight: 400;
  transition: background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: background $action-transition-duration $action-transition-timing-function;
  -moz-transition: background $action-transition-duration $action-transition-timing-function;
  -ms-transition: background $action-transition-duration $action-transition-timing-function;
  background: url(../images/other/navbar-cover.jpg) center center no-repeat;
  background-size: cover;
  height: $navbar-full-height;
  width: auto;

  .navbar-menu-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    color: $navbar-menu-color;
    margin-left: 1.437rem;
    margin-right: 1.437rem;
    width: 100%;
    height: $navbar-height;
    @media (max-width: 991px) {
      margin-left: 15px;
      margin-right: 15px;
    }
    .navbar-brand-wrapper {
      .navbar-brand {
        margin-left: 2.2rem;
        &.brand-logo-mini {
          display: none;
        }
        &.brand-logo {
          display: inline-block;
        }
        @media (max-width: 767px) {
          &.brand-logo {
            img {
              height: 36px;
            }
          }
        }
      }
    }
    .navbar-toggler {
      border: 0;
      color: inherit;
      font-size: 1.5rem;
      padding: 0;
      &:focus {
        box-shadow: none;
        border: none;
        outline: 0;
      }
      &:not(.navbar-toggler-right) {
        @media (max-width: 991px) {
          display: none;
        }
      }
      &.navbar-toggler-right {
        @media (max-width: 991px) {
          padding-left: 15px;
          padding-right: 11px;
        }
      }
    }

    .navbar-nav {
      @extend .d-flex;
      @extend .flex-row;
      @extend .align-items-center;
      .nav-item {
        margin-left: 1rem;
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
        .nav-link {
          color: inherit;
          font-size: $navbar-font-size;
        }
        &.nav-search {
          .input-group {
            background: rgba($white, 0.13);
            border-radius: 4px;
            padding: 0.75rem;
            .form-control,
            .input-group-text {
              background: transparent;
              border: 0;
              color: $white;
              padding: 0;
            }
            .form-control {
              color: $black;
              margin-left: 0.5rem;
              @include placeholder {
                color: #001737;
                font-weight: 600;
              }
            }
          }
        }
        &.dropdown {
          .dropdown-menu {
            @extend .dropdownAnimation;
            border: none;
            -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
          }
          .navbar-dropdown {
            position: absolute;
            font-size: 0.9rem;
            margin-top: 0;
            right: 0;
            left: auto;
            top: $navbar-height;

            .dropdown-item {
              @extend .d-flex;
              @extend .align-items-center;
              margin-bottom: 0;
              padding: 0.65rem 1.5rem;
              cursor: pointer;

              i {
                font-size: 17px;
              }

              .badge {
                margin-left: 2.5rem;
              }
              .ellipsis {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .dropdown-divider {
              margin: 0;
            }
          }
          i {
            margin-right: 0.5rem;
            vertical-align: middle;
          }
          @media (max-width: 991px) {
            position: static;
            .navbar-dropdown {
              left: 20px;
              right: 20px;
              top: $navbar-height;
              width: calc(100% - 40px);
            }
          }
          .count-indicator {
            position: relative;
            padding: 0;
            text-align: center;
            i {
              font-size: 1.5rem;
              margin-right: 0;
              vertical-align: middle;
            }
            .count {
              position: absolute;
              left: 35%;
              width: 21px;
              height: 21px;
              border-radius: 10px;
              top: -2px;
              font-size: 10px;
              line-height: 21px;
            }
            &:after {
              display: none;
            }
          }
        }
      }
      &.navbar-nav-right {
        @extend .align-self-stretch;
        @extend .align-items-stretch;
        .nav-item {
          @extend .d-flex;
          @extend .align-items-center;
        }
        @media (min-width: 992px) {
          margin-left: auto;
        }
      }
    }
    &.navbar-search-wrapper {
      background: $white;
      height: $navbar-height;
      .navbar-nav {
        &.navbar-nav-right {
          .nav-item {
            margin-left: 0.75rem;
            margin-right: 0.75rem;
            .nav-link {
              &.icon-link {
                color: $black;
                font-size: 1.55rem;
              }
            }
            &.nav-profile {
              @extend .d-flex;
              @extend .align-items-center;
              color: #001737;
              .nav-link {
                img {
                  width: 2.187rem;
                  height: 2.187rem;
                  border-radius: 0.25rem;
                }
                .nav-profile-name {
                  margin-left: 0.5rem;
                  font-size: 0.875rem;
                  @media (max-width: 767px) {
                    display: none;
                  }
                }
                &.dropdown-toggle {
                  &::after {
                    font-size: 1.5rem;
                  }
                }
              }
            }
            &.dropdown {
              .navbar-dropdown {
                top: $navbar-height;
              }
            }
            &:last-child {
              margin-right: 1.75rem;
            }
          }
        }
      }
    }
  }

  &.navbar-mini {
    height: $navbar-height;
    @extend .p-0;
    &.fixed-top {
      left: $sidebar-width-lg;
      transition: left $action-transition-duration $action-transition-timing-function,
        width $action-transition-duration $action-transition-timing-function;
      @media (max-width: 991px) {
        left: 0;
      }
    }
    .navbar-menu-wrapper {
      &.navbar-search-wrapper {
        @extend .d-none;
      }
    }
  }
}
@media (max-width: 991px) {
  .navbar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-top: 0;
    height: auto;
    .navbar-menu-wrapper {
      .navbar-brand-wrapper {
        .navbar-brand {
          margin-left: 0.875rem;
        }
      }
      .navbar-nav-right {
        margin-left: auto;
      }
    }
  }

  .navbar-collapse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width: 480px) {
  .navbar {
    .navbar-brand-wrapper {
      width: 55px;
      .brand-logo-mini {
        padding-top: 0px;
      }
    }
  }
}
